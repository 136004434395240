$(document).ready(() => {
    $('select').each((index, element) => {

        let options = {
            width: '100%',
            theme: 'bootstrap4',
            placeholder: element.getAttribute('placeholder'),
            language: { // importing language file does not work/ if its correctly imported select2 is not able to use it or throws an error
                removeAllItems: function () {
                    return 'Entferne Element(-e) aus Auswahl';
                }
            },
        };

        // if the select field should have deselectable options set allowClear to create the x button
        if($(element).hasClass('deselectable')){
            options.allowClear = true;
        }

        $(element).select2(options);
    });
});
