$(document).ready(() => {
    let checkbox = document.getElementById('checkbox_group_application');
    let body = document.getElementById('group_application_body');
    if (checkbox != null && body != null) {
        checkbox.addEventListener('change', () => {
            if (body.style.display === "none") {
                body.style.display = "block";
            } else {
                body.style.display = "none";
                document.getElementById('expected_applicants').value = null;
            }
        });
    }
});
