import intlTelInput from "intl-tel-input";
import inputTelInputUtils from "../../node_modules/intl-tel-input/build/js/utils";

$(document).ready(() => {

    if ($("[name='local_phone_number']").length) {

        let onlyCountries = [];
        $.ajax({
            url: '/isocode/ajaxIsoIndex',
            headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
            type: 'GET',
            success: function (result) {
                if (result !== undefined) {
                    onlyCountries = result;
                }
            },
            error: function () {
                //Use standard-values instead
                onlyCountries = [
                    "AL", "AT", "AU", "BE",
                    "CH", "CZ", "DE", "DK",
                    "EE", "ES", "FI", "FR",
                    "GB", "IE", "IT", "LU",
                    "LV", "NL", "NO", "PL",
                    "PT", "SE", "US", "NZ"
                ];
            },
            complete: function () {
                buildPhoneNumberPicker(onlyCountries);
            }
        });
    }


    function buildPhoneNumberPicker(onlyCountries) {
        let input = document.querySelector("#phone");
        if (input != null) {
            intlTelInput(input, {
                hiddenInput: "phone_number",
                initialCountry: "de",
                onlyCountries: onlyCountries,
                utilsScript: inputTelInputUtils,
            });
        }
    }
});
