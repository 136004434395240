import de from 'bootstrap-datepicker/js/locales/bootstrap-datepicker.de.js'

$(document).ready(function () {

    $('#bootstrap-datepicker').datepicker({
        startView: 0,
        daysOfWeekDisabled: [0, 1, 2, 3, 4, 5],
        minViewMode: 0,
        maxViewMode: 2,
        multidate: true,
        multidateSeparator: " - ",
        autoClose: true,
        beforeShowDay: highlightRange,
        language: "de-DE",
        locale: de,
        startDate: new Date(),
        endDate: new Date($('#bootstrap-datepicker').data('date-end'))
    }).on("changeDate", function (event) {

        let dates = event.dates;
        let bootstrapDatepicker = $('#bootstrap-datepicker');

        if(dates.length === 1){

            let selectedDate = new Date(dates[0]);
            let today = new Date();
            let seasonEnd = new Date(bootstrapDatepicker.data('date-end'));

            // use time and milliseconds because js gets weird when subtracting days
            let startDate = new Date(selectedDate.getTime() - (3 * 7 * 24 * 60 * 60 * 1000));

            // start date may be not before today
            if(startDate < today){
                startDate = today;
            }

            let endDate = new Date(selectedDate.getTime() + (3 * 7 * 24 * 60 * 60 * 1000));

            // end date may not be after season end
            if(endDate > seasonEnd){
                endDate = seasonEnd;
            }

            // call datepicker and execute setEndDate and setStartDate method because only a given range is wanted by the zwrdk
            bootstrapDatepicker.datepicker('setStartDate', startDate);
            bootstrapDatepicker.datepicker('setEndDate', endDate);
        }

        // the last date got unselected
        if(dates.length === 0){

            //reset start and end
            bootstrapDatepicker.datepicker('setStartDate', new Date());
            bootstrapDatepicker.datepicker('setEndDate', new Date(bootstrapDatepicker.data('date-end')));
        }

        // 0 or one date or undefined, do nothing more
        if (bootstrapDatepicker.data("selecteddates") === dates.join(",")) {

            return;
        }
        // too many dates, remove everything but the last selected date
        if (dates.length > 2) {
            dates = dates.splice(dates.length - 1);
        }

        // sort because user won't always pick the starting date first
        dates.sort(function (a, b) {
            return new Date(a).getTime() - new Date(b).getTime()
        });

        bootstrapDatepicker.data("selecteddates", dates.join(",")).datepicker('setDates', dates);
    });

    /**
     * Set css classes for the display
     *
     * @param date
     * @returns {string}
     */
    function highlightRange(date) {

        let selectedDates = $('#bootstrap-datepicker').datepicker('getDates');

        if (selectedDates.length === 1 && date.toDateString() === selectedDates[0].toDateString()){
            return 'highlighted first-date';
        }

        if (selectedDates.length === 2 && date >= selectedDates[0] && date <= selectedDates[1]) {

            if(date.toDateString() === selectedDates[0].toDateString()){
                return 'highlighted first-date';
            }
            else if(date.toDateString() === selectedDates[1].toDateString()){
                return 'highlighted last-date';
            }
            else{
                return 'highlighted';
            }

        }
        return '';
    }
});
